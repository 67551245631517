.ui.menu a.item:hover {
  color: black !important;
  font-weight: bolder !important;
  background-color: #dee5eb !important;
}

.margin-top-medium {
  margin-top: 20px;
}

.narrow-text {
  width: 10em;
}

.margin-bottom-large {
  margin-bottom: 40px;
}

.margin-left-medium {
  margin-left: 20px;
}

.pointer {
  cursor: pointer;
}

.narrow-right-margin {
  margin-right: 10px;
}

.vertical-align-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

text-align-center {
  text-align: center;
}

.spacer-small {
  min-height: 10px;
}

.spacer-wide {
  min-height: 50px;
}

.spacer-very-wide {
  min-height: 100px;
}

.sealer-card {
  cursor: pointer;
}

.wide-padding {
  padding: 10%;
}

.narrow-padding {
  padding: 2%;
}

.light-border {
  border: 1px solid gainsboro;
}

div,
input {
  font-family: "Open Sans", sans-serif !important;
}

h4,
h2,
h3 {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 600 !important;
  color: mediumblue;
}

.ui.message {
  font-size: 95% !important;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}
